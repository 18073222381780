<template>
  <div
    style="height: 44px"
    class="my-2 ant-glass-background project-list-item"
    @click="navigateToProject"
  >
    <div v-if="hasImage" class="pos-abs full-width full-height">
      <template v-if="!image">
        <v-skeleton-loader height="44" type="image" />
      </template>
      <div
        v-else
        class="project-card-image"
        :style="{
          'background-image': image
            ? `url(data:${image.mimeType};base64,${image.file})`
            : '',
        }"
      />
    </div>
    <div
      class="d-flex align-center white--text px-3 py-2 pos-rel"
      style="z-index: 1"
      :style="{ background: getBackgroundThemeGradient() }"
    >
      <div>{{ project.name }}</div>
      <v-spacer />
      <div class="d-flex align-center">
        <div class="fs-12">{{ project.number }}</div>
        <ant-favorite-action
          v-if="favorite"
          class="favorite-action"
          :selected="project.is_favorite"
          :loading="loading"
          @on-change="onFavoriteChange"
        />
      </div>
    </div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="primary lighten-4"
    />
  </div>
</template>

<script>
import { loadProjectImage } from '@/services/api/projects.api';
import AntFavoriteAction from '@/components/AntFavoriteAction.vue';
import ColorHelper from '@/services/color-helper';

export default {
  name: 'ProjectListCard',
  components: { AntFavoriteAction },
  props: {
    project: {
      type: Object,
      required: true,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageId: null,
      imageObject: null,
    };
  },
  computed: {
    image() {
      if (this.project.image && typeof this.project.image === 'object') {
        return {
          mimeType: this.project.image.mimeType,
          file: this.project.image.file,
        };
      }
      return this.imageObject;
    },
    hasImage() {
      return this.project?.image;
    },
    isLoading() {
      return this.loading || this.favoriteRequest;
    },
    favoriteRequest() {
      return (
        this.$wait.is(`project.favorite.add.${this.project.id}`) ||
        this.$wait.is(`project.favorite.delete.${this.project.id}`)
      );
    },
  },
  watch: {
    project: {
      handler(project) {
        if (typeof project.image === 'object') {
          this.imageObject = project.image;
        }
        if (
          typeof project.image === 'string' &&
          project.image !== this.imageId
        ) {
          loadProjectImage(project.id, project.image).then((image) => {
            this.imageId = image.id;
            this.imageObject = image;
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    navigateToProject() {
      this.$store.dispatch('setProject', this.project);
      this.$router.push({
        name: 'Project',
        params: { slug: this.project.slug },
      });
    },
    getBackgroundThemeGradient() {
      let theme = this.$vuetify.theme.themes.light.primary;

      let rgb = ColorHelper.hexToRgb(theme);
      let themeRGB100 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`;
      let themeRGB0 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`;
      return `linear-gradient(
              23deg,
              ${themeRGB100} 0%,
              ${themeRGB0} 45%,
              ${themeRGB0} 75%,
              ${themeRGB100} 100%)`;
    },
    onFavoriteChange(favoriteState) {
      if (!favoriteState) {
        this.$store
          .dispatch('addProjectToFavorites', this.project.id)
          .then(() => {
            this.$emit('favorite-change');
          });
        return;
      }
      this.$store
        .dispatch('deleteProjectFromFavorites', this.project.id)
        .then(() => {
          this.$emit('favorite-change');
        });
    },
  },
};
</script>

<style scoped lang="scss">
.project-list-item {
  cursor: pointer;
  background-color: var(--v-primary-lighten5);
  transition: 200ms ease-out;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: var(--v-primary-base);
    transform: translateX(-7px);
  }
}

.project-card-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}
</style>
