<template>
  <div class="ant-glass-background user-display-container">
    <div class="d-flex align-center">
      <v-avatar
        color="primary"
        size="70"
        class="mr-2"
      >
        <v-img
          v-if="user.photo"
          :src="`data:${user.photo.mimetype};base64, ${user.photo.file}`"
        />
        <span
          v-else
          class="white--text text-h4"
        >
          {{ user.firstname.charAt(0) + user.lastname.charAt(0) }}
        </span>
      </v-avatar>

      <div class="user-info">
        <div class="user-name">
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div
          v-if="selectedLicense"
          class="user-info-label"
        >
          license: <span>{{ selectedLicense.name }}</span>
        </div>
        <div class="user-info-label">
          email: <span>{{ user.email }}</span>
        </div>
        <div
          v-if="user.company"
          class="user-info-label"
        >
          company: <span>{{ user.company.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "UserDisplay",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['selectedLicense'])
  }

}
</script>

<style scoped lang="scss">
.user-display-container {
  padding: 20px;
  display: flex;
  flex-direction: column;

  &:before {
    box-shadow: inset 0 0 0 200px rgb(255 255 255 / 20%);
    filter: blur(10px)
  }

  .user-header {
    font-weight: bold;
    font-size: 34px;
    text-align: center;
  }

    .user-info {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .user-name {
        font-size: 20px;
      }

      .user-info-label {
        color: rgb(150, 150, 150);
        font-style: italic;
        font-size: 12px;

        span {
          font-style: normal;
        }
      }

    }



}

</style>
